import { APP_INITIALIZER, ErrorHandler, StaticProvider } from '@angular/core';
import { Router } from '@angular/router';
import { RuntimeConfigurationModel } from '@core/models/runtime-configuration.model';
import {
  BrowserOptions,
  BrowserTracing,
  Replay,
  Feedback,
  TraceService,
  createErrorHandler,
  init,
} from '@sentry/angular-ivy';

export function initClient(config: RuntimeConfigurationModel): void {
  if (config.sentry.enable) {
    const { options, integrations } = config.sentry;
    const browserOptions: BrowserOptions = { ...options, integrations: [] };
    const intergrationObjects: BrowserOptions['integrations'] = [];

    for (const integration of Object.keys(integrations)) {
      switch (integration) {
        case 'browser-tracing':
          const browserTracing = new BrowserTracing(integrations[integration]);
          intergrationObjects.push(browserTracing);
          break;
        case 'replay':
          const replay = new Replay(integrations[integration]);
          intergrationObjects.push(replay);
          break;
        case 'feedback':
          const feedback = new Feedback(integrations[integration]);
          intergrationObjects.push(feedback);
          break;
      }
    }

    browserOptions.integrations = intergrationObjects;

    init(browserOptions);
  }
}

export function createProviders(): StaticProvider[] {
  return [
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({ showDialog: true }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ];
}
