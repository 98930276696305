import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ArcContainer, ArcNavbar } from '@arc-web/components';
import { AuthStore } from '@core/state/auth.store';
import { SettingsStore } from '@core/state/settings.store';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';
import { RuntimeConfigurationModel } from '@core/models/runtime-configuration.model';
import { LocalStorageService } from '@core/services/local-storage.service';
import { ContainerTheme } from '@arc-web/components/dist/components/container/constants/ContainerConstants';
import { PermissionsService } from '@core/services/permissions.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NotificationService } from '@core/services/notification.service';
import {
  CONFIG as ACKNOWLEDGEMENT_MODAL_CONFIG,
  Data as AcknowledgementModalData,
  Result as AcknowledgementModalResult,
  AcknowledgementModalComponent,
  resultIsObject,
} from '@core/modals/acknowledgement-modal/acknowledgement-modal.component';
import { WINDOW } from '@core/tokens/window.token';
import { DOCUMENT } from '@angular/common';
import { combineLatest, map, of, Observable } from 'rxjs';

interface LayoutViewModel {
  authenticated: boolean;
  userCanManageProjects: boolean;
  maintenance: boolean;
  smallScreen: boolean;
}

@Component({
  selector: 'zero-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements AfterViewInit {
  @ViewChild('container') container?: ElementRef<ArcContainer>;
  @ViewChild('navbar') navbar?: ElementRef<ArcNavbar>;

  readonly vm$: Observable<LayoutViewModel> = combineLatest([
    this.authStore.authenticated$,
    this.permissionService.userCanManageProjects$,
    of(this.runtimeConfiguration.maintenance),
    this.breakpointObserver
      .observe(['(max-width: 720px)'])
      .pipe(map((result) => result.matches)),
  ]).pipe(
    map(([authenticated, userCanManageProjects, maintenance, smallScreen]) => ({
      authenticated,
      userCanManageProjects,
      maintenance: maintenance ?? false,
      smallScreen,
    })),
  );

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly authStore: AuthStore,
    private readonly settingsStore: SettingsStore,
    private readonly permissionService: PermissionsService,
    private readonly localStorageService: LocalStorageService,
    private readonly dialog: MatDialog,
    private readonly notification: NotificationService,
    private readonly renderer: Renderer2,
    @Inject(RUNTIME_CONFIGURATION)
    private readonly runtimeConfiguration: RuntimeConfigurationModel,
    @Inject(WINDOW) private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  ngAfterViewInit(): void {
    this.container?.nativeElement.addEventListener(
      'arc-accessibility-change',
      this.handleAccessibilityChanged.bind(this) as EventListener,
    );
    if (
      this.runtimeConfiguration.env == 'test' &&
      !this.localStorageService.getItem<boolean>('arup.zero.skip-test-message')
    ) {
      this.dialog
        .open(AcknowledgementModalComponent, {
          ...ACKNOWLEDGEMENT_MODAL_CONFIG,
          data: <AcknowledgementModalData>{
            title:
              'This is a test environment. Any asset data will not stored.',
            message:
              'This is an environment for you to get familiar with the platform before submitting your final assessments for the current data collection period. Any data submitted through this view will not be stored.',
            action: 'Okay, got it!',
            skippable: true,
          },
        })
        .afterClosed()
        .subscribe((result: AcknowledgementModalResult) => {
          if (resultIsObject(result)) {
            this.localStorageService.setItem(
              'arup.zero.skip-test-message',
              result.skip,
            );
          }
        });
    }
    if (this.runtimeConfiguration.appDefaults.notification) {
      this.notification.open({
        type: this.runtimeConfiguration.appDefaults.notification.type,
        title: this.runtimeConfiguration.appDefaults.notification.title,
        message: this.runtimeConfiguration.appDefaults.notification.message,
      });
    }
  }

  handleAccessibilityChanged(
    event: CustomEvent<{ preferences: { theme: ContainerTheme } }>,
  ): void {
    this.updateTheme(event.detail.preferences.theme);
  }

  updateTheme(theme: ContainerTheme): void {
    if (theme === 'auto')
      theme = this.window.matchMedia('(prefers-color-scheme: light)').matches
        ? 'light'
        : 'dark';
    this.settingsStore.updateTheme(theme);
    this.renderer.setAttribute(this.document.body, 'theme', theme);
    this.renderer.removeClass(
      this.document.body,
      theme === 'light' ? 'mgt-dark' : 'mgt-light',
    );
    this.renderer.addClass(
      this.document.body,
      theme === 'light' ? 'mgt-light' : 'mgt-dark',
    );
    this.container?.nativeElement.setAttribute('theme', theme);
    this.navbar?.nativeElement.setAttribute(
      'logo',
      theme === 'light' && this.runtimeConfiguration.env !== 'test'
        ? this.runtimeConfiguration.appDefaults.lightLogoHeaderURL
        : this.runtimeConfiguration.appDefaults.darkLogoHeaderURL,
    );
    this.navbar?.nativeElement.setAttribute(
      'env',
      this.runtimeConfiguration.env,
    );
  }
}
