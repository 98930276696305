import { RuntimeConfigurationModel } from '@core/models/runtime-configuration.model';
import posthog from 'posthog-js';

export function initClient(config: RuntimeConfigurationModel): void {
  if (config.posthog.enabled) {
    posthog.init(config.posthog.apiKey, {
      api_host: config.posthog.apiHost,
    });
  }
}
