import { Injectable, inject } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';

@Injectable({ providedIn: 'root' })
export class MaintaincePageGuard implements CanActivate {
  private readonly maintenance = inject(RUNTIME_CONFIGURATION).maintenance;
  private readonly router = inject(Router);

  canActivate(_: unknown, state: RouterStateSnapshot): boolean {
    if (this.maintenance && state.url !== '/maintenance') {
      this.router.navigate(['/maintenance']);
    } else if (!this.maintenance && state.url === '/maintenance') {
      this.router.navigate(['/']);
    }
    return true;
  }
}
