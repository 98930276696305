import { StaticProvider } from '@angular/core';
import { RuntimeConfigurationModel } from '@core/models/runtime-configuration.model';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

function createClient(
  config: RuntimeConfigurationModel,
): PublicClientApplication {
  const { auth } = config;
  return new PublicClientApplication({ auth });
}

function createGuardConfig(): MsalGuardConfiguration {
  return <MsalGuardConfiguration>{
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}

function createInterceptorConfig(
  config: RuntimeConfigurationModel,
): MsalInterceptorConfiguration {
  const resources = new Map<string, (string | ProtectedResourceScopes)[]>();
  resources.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  resources.set('https://graph.microsoft.com/v1.0/users ', ['user.read']);
  resources.set(`${config.api.zero.baseUrl}/**`, [
    `api://${
      config.api.zero.auth?.clientId ?? config.auth.clientId
    }/user_impersonation`,
  ]);
  resources.set(`${config.api.veracity.baseUrl}/**`, [
    `api://${
      config.api.veracity.auth?.clientId ?? config.auth.clientId
    }/user_impersonation`,
  ]);
  resources.set(`${config.api.ddb.baseUrl}/**`, [
    `api://${
      config.api.ddb.auth?.clientId ?? config.auth.clientId
    }/user_impersonation`,
  ]);
  return <MsalInterceptorConfiguration>{
    interactionType: InteractionType.Popup,
    protectedResourceMap: resources,
  };
}

export function createProviders(
  config: RuntimeConfigurationModel,
): StaticProvider[] {
  return [
    {
      provide: MSAL_INSTANCE,
      useValue: createClient(config),
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: createGuardConfig(),
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useValue: createInterceptorConfig(config),
    },
  ];
}
