import { InjectionToken, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { TypesStore } from '@core/state/types.store';
import { STALE_AFTER } from '@core/tokens/stale-after.token';
import { refetchIfStale } from '@core/utils/refetch-if-stale';
import { first, forkJoin, map } from 'rxjs';

export const LOAD_TYPES_GUARD = new InjectionToken<CanActivateFn>(
  'LOAD_TYPES_GUARD',
  {
    providedIn: 'root',
    factory: () => {
      const typesStore = inject(TypesStore);
      const defaultStaleAfter = inject(STALE_AFTER).default;
      return () => {
        const reloadConstructionType = () => typesStore.loadConstructionTypes();
        const reloadAssetFunction = () => typesStore.loadAssetFunctions();
        const reloadHvacSystem = () => typesStore.loadHvacSystems();
        return forkJoin([
          typesStore.constructionTypes$.pipe(
            first(),
            refetchIfStale(defaultStaleAfter, reloadConstructionType),
          ),
          typesStore.assetFunctions$.pipe(
            first(),
            refetchIfStale(defaultStaleAfter, reloadAssetFunction),
          ),
          typesStore.hvacSystems$.pipe(
            first(),
            refetchIfStale(defaultStaleAfter, reloadHvacSystem),
          ),
        ]).pipe(map(() => true));
      };
    },
  },
);
