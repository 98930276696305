import { InjectionToken, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { CollectionStore } from '@collection/state/collection.store';
import { STALE_AFTER } from '@core/tokens/stale-after.token';
import { refetchIfStale } from '@core/utils/refetch-if-stale';
import { first } from 'rxjs';

export const LOAD_COLLECTION_GUARD = new InjectionToken<CanActivateFn>(
  'LOAD_COLLECTION_GUARD',
  {
    providedIn: 'root',
    factory: () => {
      const collectionStore = inject(CollectionStore);
      const staleAfter = inject(STALE_AFTER).collection;
      const loadCollection = () => collectionStore.loadAssets();
      return () => {
        collectionStore
          .assets()
          .pipe(refetchIfStale(staleAfter, loadCollection), first())
          .subscribe();
        return true;
      };
    },
  },
);
