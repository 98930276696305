import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LayoutComponent } from '@shell/containers/layout/layout.component';
import { MaintaincePageGuard } from '@core/guards/maintenance-page.guard';
import { LandingPageComponent } from '@core/containers/landing-page/landing-page.component';
import { UnauthorizedPageComponent } from '@core/containers/unauthorized-page/unauthorized-page.component';
import { NotFoundPageComponent } from '@core/containers/not-found-page/not-found-page.component';
import { MaintenancePageComponent } from '@core/containers/maintenance-page/maintenance-page.component';
import { LOAD_TYPES_GUARD } from '@core/guards/load-types.guard';
import { LOAD_CAMPAIGNS_GUARD } from '@core/guards/load-campaigns.guard';
import { LOAD_COLLECTION_GUARD } from '@collection/guards/load-collection.guard';

export const SHELL_ROUTES: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        canActivate: [MaintaincePageGuard],
        children: [
          {
            path: '',
            component: LandingPageComponent,
          },
          {
            path: '',
            canActivateChild: [
              MsalGuard,
              LOAD_TYPES_GUARD,
              LOAD_CAMPAIGNS_GUARD,
              LOAD_COLLECTION_GUARD,
            ],
            children: [
              {
                path: 'asset',
                loadChildren: () =>
                  import('@asset/asset.module').then((m) => m.AssetModule),
              },
              {
                path: 'collection',
                loadChildren: () =>
                  import('@collection/collection.module').then(
                    (m) => m.CollectionModule,
                  ),
              },
              {
                path: 'operational-assessment',
                loadChildren: () =>
                  import(
                    '@operational-assessment/operational-assessment.module'
                  ).then((m) => m.OperationalAssessmentModule),
              },
              {
                path: 'detailed-assessment',
                loadChildren: () =>
                  import(
                    '@detailed-assessment/detailed-assessment.module'
                  ).then((m) => m.DetailedAssessmentModule),
              },
              {
                path: 'energy-model-assessment',
                loadChildren: () =>
                  import(
                    '@energy-model-assessment/energy-model-assessment.module'
                  ).then((m) => m.EnergyModelAssessmentModule),
              },
              {
                path: 'embodied-assessment',
                loadChildren: () =>
                  import(
                    '@embodied-assessment/embodied-assessment.module'
                  ).then((m) => m.EmbodiedAssessmentModule),
              },
              {
                path: 'project',
                loadChildren: () =>
                  import('@project/project.module').then(
                    (m) => m.ProjectModule,
                  ),
              },
            ],
          },
          {
            path: 'maintenance',
            component: MaintenancePageComponent,
          },
          {
            path: 'unauthorized',
            component: UnauthorizedPageComponent,
          },
          {
            path: '**',
            component: NotFoundPageComponent,
          },
        ],
      },
    ],
  },
];
