import { InjectionToken, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { CampaignStore } from '@core/state/campaign.store';
import { STALE_AFTER } from '@core/tokens/stale-after.token';
import { refetchIfStale } from '@core/utils/refetch-if-stale';
import { first } from 'rxjs';

export const LOAD_CAMPAIGNS_GUARD = new InjectionToken<CanActivateFn>(
  'LOAD_CAMPAIGNS_GUARD',
  {
    providedIn: 'root',
    factory: () => {
      const campaignStore = inject(CampaignStore);
      const staleAfter = inject(STALE_AFTER).default;
      return () => {
        const loadCampaigns = () => campaignStore.loadCampaigns();
        campaignStore.campaigns$
          .pipe(refetchIfStale(staleAfter, loadCampaigns), first())
          .subscribe();
        return true;
      };
    },
  },
);
