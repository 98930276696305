import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { ExtraOptions, RouterModule } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import {
  TippyModule,
  popperVariation,
  tooltipVariation,
} from '@ngneat/helipopper';
import { TippyConfig } from '@ngneat/helipopper/lib/tippy.types';
import { MaterialModule } from '@material/material.module';
import { LayoutComponent } from '@shell/containers/layout/layout.component';
import { DdbModule } from '@ddb/ddb.module';
import { SHELL_ROUTES } from '@shell/shell.routes';

const TIPPY_CONFIG: Partial<TippyConfig> = {
  defaultVariation: 'tooltip',
  variations: {
    tooltip: tooltipVariation,
    popper: popperVariation,
  },
};

const ROUTER_CONFIG: ExtraOptions = {
  initialNavigation:
    !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
      ? 'enabledNonBlocking'
      : 'disabled',
};

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    CoreModule,
    DdbModule,
    MaterialModule,
    TippyModule.forRoot(TIPPY_CONFIG),
    RouterModule.forRoot(SHELL_ROUTES, ROUTER_CONFIG),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShellModule {}
