import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';
import {
  initClient as initSentry,
  createProviders as createSentryProviders,
} from '@core/utils/sentry';
import { createProviders as createMsalProviders } from '@core/utils/msal';
import { initClient as initPosthog } from '@core/utils/posthog';

import { AppModule } from './app/app.module';

import { environment } from './environments/environment';
if (environment.production) enableProdMode();

fetch('configuration/config.json')
  .then((response) => response.json())
  .then((config) => {
    initSentry(config);
    const sentryProviders = createSentryProviders();
    const msalProviders = createMsalProviders(config);
    initPosthog(config);
    return platformBrowserDynamic([
      { provide: RUNTIME_CONFIGURATION, useValue: config },
      ...msalProviders,
      ...sentryProviders,
    ]).bootstrapModule(AppModule);
  })
  .catch(console.error);
